// @flow
import styled from '@emotion/styled';

const ToolbarDelimitr: React$ComponentType<{||}> = styled('div')`
	background-color: ${({ theme }) => theme.colors.spec.lightblue30};
	margin-left: 6px;
	margin-right: 6px;
	width: 1px;
	align-self: stretch;
`;

export default ToolbarDelimitr;
