// @flow
import type { TPresetColors } from '@graphite/types';

export const namedColors: TPresetColors = {
	primary: {
		text: 'text.primary',
		texthover: 'text.accent',
		textfocus: 'text.accent',
		textactive: 'text.accent',
		bg: 'bg.primaryalt',
		bghover: 'bg.primaryaltplus',
		bgfocus: 'bg.primaryaltplus',
		bgactive: 'bg.primaryaltminus',
		border: 'bg.primaryalt',
		borderhover: 'bg.primaryaltplus',
		borderfocus: 'bg.primaryaltplus',
		borderactive: 'bg.primaryaltminus',
	},
	secondary: {
		text: 'text.secondary',
		texthover: 'text.accent',
		textfocus: 'text.accent',
		textactive: 'text.accent',
		bg: 'bg.primaryalt',
		bghover: 'bg.primaryaltplus',
		bgfocus: 'bg.primaryaltplus',
		bgactive: 'bg.primaryaltminus',
		border: 'bg.primaryalt',
		borderhover: 'bg.primaryaltplus',
		borderfocus: 'bg.primaryaltplus',
		borderactive: 'bg.primaryaltminus',
	},
	accent: {
		text: 'text.oncolor',
		texthover: 'text.oncolor',
		textfocus: 'text.oncolor',
		textactive: 'text.oncolor',
		bg: 'bg.accent',
		bghover: 'bg.accentplus',
		bgfocus: 'bg.accentplus',
		bgactive: 'bg.accentminus',
		border: 'bg.accent',
		borderhover: 'bg.accentplus',
		borderfocus: 'bg.accentplus',
		borderactive: 'bg.accentminus',
	},
	success: {
		text: 'text.oncolor',
		texthover: 'text.oncolor',
		textfocus: 'text.oncolor',
		textactive: 'text.oncolor',
		bg: 'bg.success',
		bghover: 'bg.successplus',
		bgfocus: 'bg.successplus',
		bgactive: 'bg.successminus',
		border: 'bg.success',
		borderhover: 'bg.successplus',
		borderfocus: 'bg.successplus',
		borderactive: 'bg.successminus',
	},
	error: {
		text: 'text.oncolor',
		texthover: 'text.oncolor',
		textfocus: 'text.oncolor',
		textactive: 'text.oncolor',
		bg: 'bg.error',
		bghover: 'bg.errorplus',
		bgfocus: 'bg.errorplus',
		bgactive: 'bg.errorminus',
		border: 'bg.error',
		borderhover: 'bg.errorplus',
		borderfocus: 'bg.errorplus',
		borderactive: 'bg.errorminus',
	},
	primaryghost: {
		text: 'text.primary',
		texthover: 'text.oncolor',
		textfocus: 'text.oncolor',
		textactive: 'text.oncolor',
		bg: 'transparent',
		bghover: 'bg.accent',
		bgfocus: 'bg.accent',
		bgactive: 'bg.accentminus',
		border: 'bg.primaryalt',
		borderhover: 'bg.accent',
		borderfocus: 'bg.accent',
		borderactive: 'bg.accentminus',
	},
	accentghost: {
		text: 'text.primary',
		texthover: 'text.oncolor',
		textfocus: 'text.oncolor',
		textactive: 'text.oncolor',
		bg: 'transparent',
		bghover: 'bg.accentplus',
		bgfocus: 'bg.accentplus',
		bgactive: 'bg.accentminus',
		border: 'bg.accent',
		borderhover: 'bg.accentplus',
		borderfocus: 'bg.accentplus',
		borderactive: 'bg.accentminus',
	},
	primaryflat: {
		text: 'text.primary',
		texthover: 'text.accent',
		textfocus: 'text.accent',
		textactive: 'text.accent',
		bg: 'transparent',
		bghover: 'transparent',
		bgfocus: 'transparent',
		bgactive: 'transparent',
		border: 'bg.accent',
		borderhover: 'bg.accent',
		borderfocus: 'bg.accent',
		borderactive: 'bg.accent',
	},
	primaryaltflat: {
		text: 'text.primaryalt',
		texthover: 'text.accent',
		textfocus: 'text.accent',
		textactive: 'text.accent',
		bg: 'transparent',
		bghover: 'transparent',
		bgfocus: 'transparent',
		bgactive: 'transparent',
		border: 'bg.accent',
		borderhover: 'bg.accent',
		borderfocus: 'bg.accent',
		borderactive: 'bg.accent',
	},
	secondaryflat: {
		text: 'text.secondary',
		texthover: 'text.accent',
		textfocus: 'text.accent',
		textactive: 'text.accent',
		bg: 'transparent',
		bghover: 'transparent',
		bgfocus: 'transparent',
		bgactive: 'transparent',
		border: 'bg.secondary',
		borderhover: 'bg.accentplus',
		borderfocus: 'bg.accentplus',
		borderactive: 'bg.accentplus',
	},
	baseflat: {
		text: 'spec.darkblue60',
		texthover: 'spec.darkblue60',
		textfocus: 'spec.darkblue60',
		textactive: 'spec.darkblue60',
		bg: 'transparent',
		bghover: 'transparent',
		bgfocus: 'transparent',
		bgactive: 'transparent',
		border: 'transparent',
		borderhover: 'transparent',
		borderfocus: 'transparent',
		borderactive: 'transparent',
	},
	accentflat: {
		text: 'text.accent',
		texthover: 'text.accentplus',
		textfocus: 'text.accentplus',
		textactive: 'text.accentminus',
		bg: 'transparent',
		bghover: 'transparent',
		bgfocus: 'transparent',
		bgactive: 'transparent',
		border: 'bg.accent',
		borderhover: 'bg.accentplus',
		borderfocus: 'bg.accentplus',
		borderactive: 'bg.accent',
	},
	tertiaryflat: {
		text: 'text.tertiary',
		texthover: 'text.accent',
		textfocus: 'text.accent',
		textactive: 'text.accentminus',
		bg: 'transparent',
		bghover: 'transparent',
		bgfocus: 'transparent',
		bgactive: 'transparent',
		border: 'bg.primaryalt',
		borderhover: 'bg.secondaryminus',
		borderfocus: 'bg.secondaryminus',
		borderactive: 'bg.accent',
	},
};

export default {};
