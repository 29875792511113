// @flow
import React, { memo, useMemo } from 'react';
import { Text } from 'rebass';
import _ from 'lodash/fp';
import type { TListItemTextEmbed, TListItemOnClick } from '@graphite/types';

import ListItem from '../ListItem';
import { namedColors } from '../constants';

type TProps = $ReadOnly<{|
	...TListItemTextEmbed,
	onClick?: TListItemOnClick,
|}>;

const ListItemText = ({
	label = null,
	name = null,
	size = 'lg',
	colors = 'primary',
	isActive = false,
	isDisabled = false,
	isExplicit = false,
	buttonGroup = null,
	variant = 'bodysm',
	sx = null,
	style = null,
	onClick,
}: TProps) => {
	const { text, texthover, textfocus, textactive } =
		(colors !== null && typeof colors === 'object' && colors) ||
		(typeof colors === 'string' && namedColors[colors]) ||
		namedColors.primary;

	const sxExtended = useMemo(
		() => ({
			color: isActive ? texthover : text,
			...sx,
			...((!isActive && {
				':hover': _.assign(
					{ color: texthover },
					sx && typeof sx[':hover'] === 'object' && !Array.isArray(sx[':hover'])
						? sx[':hover']
						: null,
				),
				':focus': _.assign(
					{ color: textfocus },
					sx && typeof sx[':focus'] === 'object' && !Array.isArray(sx[':focus'])
						? sx[':focus']
						: null,
				),
				':active': _.assign(
					{ color: textactive },
					sx &&
						typeof sx[':active'] === 'object' &&
						!Array.isArray(sx[':active'])
						? sx[':active']
						: null,
				),
			}) ||
				null),
		}),
		[isActive, sx, text, textactive, textfocus, texthover],
	);

	return (
		<ListItem
			name={name}
			size={size}
			colors={colors}
			isActive={isActive}
			isDisabled={isDisabled}
			isExplicit={isExplicit}
			buttonGroup={buttonGroup}
			sx={sxExtended}
			style={style}
			onClick={onClick}
		>
			{label && <Text variant={variant}>{label}</Text>}
		</ListItem>
	);
};

export default memo<TProps>(ListItemText);
