// @flow
import React, { memo, useMemo, useState } from 'react';
import type { TSelectEmbed, TPopupOnClick } from '@graphite/types';

import PopupMenu from '../PopupMenu';

export type TProps = $ReadOnly<{|
	children?: ?React$Node,
	footer?: ?React$Node,
	...TSelectEmbed,
	onClick?: ?TPopupOnClick,
|}>;

function SelectSimple({
	children = null,
	footer = null,
	list,
	name = null,
	label = '',
	title = '',
	colors = 'primary',
	size = 'sm',
	isDisabled = false,
	isWide = false,
	isActive = false,
	as = 'div',
	sx = null,
	buttonSx = null,
	style = null,
	variant = 'flat',
	shape = 'normal',
	onClick = null,
}: TProps) {
	const [isOpen, setIsOpen] = useState(false);

	const activeItem = useMemo(
		() => list.items.find(button => button.name === list.active),
		[list.active, list.items],
	);

	const buttonProps = useMemo(
		() => ({
			label: activeItem ? activeItem.label : label,
			size,
			colors,
			as,
			variant,
			isDisabled,
			isWide,
			isActive,
			shape,
			name,
			title,
			sx: buttonSx,
			icons: [
				null,
				{
					name: 'expand-small-top',
					rotation: isOpen ? 0 : 180,
					colors: 'tertiaryflat',
					size: 'xxsm',
					sx: {
						marginLeft: '6px',
					},
				},
			],
		}),
		[
			activeItem,
			as,
			buttonSx,
			colors,
			isActive,
			isDisabled,
			isOpen,
			isWide,
			label,
			name,
			shape,
			size,
			title,
			variant,
		],
	);

	const listExtended = useMemo(
		() => ({
			...list,
			colors: list.colors || 'primaryflat',
			activeColors: list.activeColors || 'accentflat',
			behavior: 'radio',
		}),
		[list],
	);

	return (
		<PopupMenu
			button={buttonProps}
			list={listExtended}
			sx={sx}
			style={style}
			onClick={onClick}
			onOpen={setIsOpen}
			footer={footer}
		>
			{children}
		</PopupMenu>
	);
}

export default memo<TProps>(SelectSimple);
