// @flow
import React from 'react';
import { ToolbarDelimitr } from '@graphite/uneon';
import type { TWidgetDiff } from '@graphite/types';
import { Design as SelectDesign } from '@graphite/selects';
import { useTranslation } from 'react-i18next';

import { genId } from 'libs/firebase';
import { updateSpecs } from 'Editor/ducks/specs';
import { editWidget } from 'Editor/ducks/widgets';
import ToolbarLinkButton from 'Editor/Buttons/Toolbar/Link';
import ToolbarLayoutButton from 'Editor/Buttons/Toolbar/Layout';
import { defaultDesignBreakpointButton, defaultDesign } from '@graphite/constants';
import DesignPanel from '@graphite/design-panel';
import DesignColor from './Design/Color';
import DesignStyle from './Design/Style';

import type { TConnectPropsWithControls } from '../constants/types';
import Align from './Align';
import Settings from './Settings';

const Controls = ({
	colorspec,
	gridspec,
	effectspec,
	widgetspec,
	position,
	currentDevice,
	dispatch,
	data,
	instanceId,
	originId,
	currentRef,
	insertImage,
	removeImage,
	resetImage,
	images,
	uploads,
}: TConnectPropsWithControls) => {
	const { t } = useTranslation();

	const { _id, designId, designs } = data;

	const [openedPanel, setOpenedPanel] = React.useState(null);

	const defaultDesignButton = React.useMemo(
		() => ({
			...defaultDesign,
			breakpoints: {
				[`${currentDevice}`]: defaultDesignBreakpointButton,
			},
		}),
		[currentDevice],
	);

	const saveDiff = React.useCallback(
		(diff: TWidgetDiff) => {
			if (!originId) return;
			dispatch(editWidget(_id, instanceId, originId, diff));
		},
		[_id, instanceId, originId, dispatch],
	);

	const save = React.useCallback(
		(name, value: any) => {
			if (!originId) return;
			dispatch(
				editWidget(_id, instanceId, originId, {
					[name]: value,
				}),
			);
		},
		[_id, instanceId, originId, dispatch],
	);

	const customDesign = (designs && designId && designs[designId]) || null;

	const saveDesign = React.useCallback(
		(designId, design) => {
			if (!designId && !design) {
				return;
			}
			if (!originId) return;
			const diff = {};
			if (designId) {
				diff.designId = designId;
			}
			if (design) {
				diff.designs = {
					...designs,
					[design._id]: design,
				};
			}
			dispatch(editWidget(_id, instanceId, originId, diff));
		},
		[_id, designs, dispatch, instanceId, originId],
	);

	const baseDesign = React.useMemo(
		() =>
			customDesign ||
			(designId && widgetspec.button.find(b => b._id === designId)) ||
			defaultDesignButton,
		[customDesign, defaultDesignButton, designId, widgetspec.button],
	);

	const updateSpecsHandler = React.useCallback(
		specs => {
			dispatch(updateSpecs(specs));
		},
		[dispatch],
	);

	return (
		<>
			<SelectDesign
				target="button"
				designId={designId}
				device={currentDevice}
				customDesign={customDesign}
				defaultDesign={baseDesign}
				DesignPanel={DesignPanel}
				DesignColor={DesignColor}
				DesignStyle={DesignStyle}
				colorspec={colorspec}
				gridspec={gridspec}
				effectspec={effectspec}
				widgetspec={widgetspec}
				onChange={saveDesign}
				t={t}
				genCustomId={genId}
				updateSpecs={updateSpecsHandler}
				insertImage={insertImage}
				removeImage={removeImage}
				resetImage={resetImage}
				images={images}
				uploads={uploads}
			/>

			<ToolbarDelimitr />

			<Align
				device={currentDevice}
				openedPanel={openedPanel}
				setOpenedPanel={setOpenedPanel}
				save={save}
				data={data}
			/>

			<Settings
				openedPanel={openedPanel}
				setOpenedPanel={setOpenedPanel}
				save={save}
				data={data}
			/>

			<ToolbarLinkButton
				openedPanel={openedPanel}
				setOpenedPanel={setOpenedPanel}
				save={save}
				link={data.link}
				colorspec={colorspec}
				gridspec={gridspec}
				genId={genId}
			/>
			<ToolbarDelimitr />
			<ToolbarLayoutButton
				openedPanel={openedPanel}
				setOpenedPanel={setOpenedPanel}
				save={saveDiff}
				data={data}
				position={position}
				currentRef={currentRef}
				unit={gridspec.unit}
				currentDevice={currentDevice}
			/>
		</>
	);
};

export default React.memo<TConnectPropsWithControls>(Controls);
