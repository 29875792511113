// @flow
// Needed for redux-saga es6 generator support
// FixMe оно скорее всего не надо уже
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Global } from '@emotion/core';

import Editor from './Editor';

import configureStore from './libs/configure-store';
import history from './libs/history';
import * as serviceWorker from './serviceWorker';
import globalStyle from './libs/globalStyle';
import ym from './libs/ym';
import fb from './libs/fb';

const MOUNT_NODE = document.getElementById('app');

let store = null;

const render = async (isStoreChanged: boolean = false) => {
	if (!store || isStoreChanged) {
		const initialState = {};
		// Create redux store with history
		store = configureStore(initialState, history);
	}

	if (MOUNT_NODE) {
		ReactDOM.render(
			<Provider store={store}>
				<Global styles={globalStyle} />
				<ConnectedRouter history={history}>
					<Editor />
				</ConnectedRouter>
			</Provider>,
			MOUNT_NODE,
		);
	}
};

/* $FlowFixMe(>=0.102.0) описать тип module
 * */
if (module.hot) {
	// Hot reloadable React components and translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	/* $FlowFixMe(>=0.102.0) описать тип module
	 * */
	module.hot.accept(['./Editor'], () => {
		ReactDOM.unmountComponentAtNode(MOUNT_NODE);
		render();
	});
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// Включаем Яндекс метрику и fb pixel
ym();
fb();
