// @flow
import React from 'react';
import { ToolbarBtn, PopupWhite, Text } from '@graphite/uneon';
import { Params as ListParams } from '@graphite/lists';
import { useTranslation } from 'react-i18next';

import type { TWidgetIcon } from '../constants/types';

type TProps = $ReadOnly<{|
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
	data: TWidgetIcon,
	save: (string, string | Object) => void,
|}>;

const CONTROL_NAME = 'gear';

const Settings = ({ openedPanel, setOpenedPanel, save, data }: TProps) => {
	const { t } = useTranslation();

	const btnEl = React.useRef(null);
	const handleToogleOpen = React.useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(openedPanel =>
				openedPanel === CONTROL_NAME ? null : CONTROL_NAME,
			);
		},
		[setOpenedPanel],
	);

	const isActive = openedPanel === CONTROL_NAME;

	const paramList = React.useMemo(() => {
		const params = [
			{
				title: t('Name'),
				key: 'iconName',
				kind: 'string',
				info: { maxLength: 140 },
			},
			{
				title: t('Size'),
				key: 'size',
				kind: 'unit',
				info: {
					showUnits: true,
					isRequired: false,
					domain: 'nonnegative',
				},
			},
			{
				title: t('Rotate'),
				key: 'rotation',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'rotationUnit',
				},
			},
			{
				title: t('Scale'),
				key: 'scale',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'scaleUnit',
				},
			},
			{
				title: t('Margin Top'),
				key: 'marginTop',
				kind: 'unit',
				info: {
					showUnits: true,
				},
			},
			{
				title: t('Margin Left'),
				key: 'marginLeft',
				kind: 'unit',
				info: {
					showUnits: true,
				},
			},
		];

		return params;
	}, [t]);

	const paramSource = React.useMemo(
		() => ({
			iconName: data.iconName || '',
			rotation: `${data.rotate || 0}`,
			size: `${data.size || ''}`,
			scale: `${data.scale || 100}`,
			marginTop: `${data.marginTop || 0}`,
			marginLeft: `${data.marginLeft || 0}`,
			rotationUnit: '°',
			scaleUnit: '%',
		}),
		[
			data.iconName,
			data.rotate,
			data.size,
			data.scale,
			data.marginTop,
			data.marginLeft,
		],
	);

	return (
		<>
			<ToolbarBtn
				onMouseDown={handleToogleOpen}
				iconName="gear"
				ref={btnEl}
				isActive={isActive}
			/>

			<PopupWhite
				isOpen={isActive}
				anchorEl={btnEl}
				offsetTop={10}
				onClose={handleToogleOpen}
				isFixed
				mutex="settings"
			>
				{isActive && (
					<>
						<Text variant="title4" color="text.primaryalt">
							{t('Settings')}
						</Text>
						<ListParams
							listName="icon"
							paramSource={paramSource}
							paramList={paramList}
							unit={1}
							onChange={save}
						/>
					</>
				)}
			</PopupWhite>
		</>
	);
};

export default React.memo<TProps>(Settings);
