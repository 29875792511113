// @flow

import React from 'react';

import type { TMethodGetStyles, TMethodGetRect, TMethodUseRect } from './types';

const getStyles: TMethodGetStyles = node => {
	const style: CSSStyleDeclaration = window.getComputedStyle(node);

	const {
		marginTop,
		marginRight,
		marginBottom,
		marginLeft,
		paddingTop,
		paddingRight,
		paddingBottom,
		paddingLeft,
	} = style;

	return {
		margin: {
			top: parseInt(marginTop, 10),
			right: parseInt(marginRight, 10),
			bottom: parseInt(marginBottom, 10),
			left: parseInt(marginLeft, 10),
		},
		padding: {
			top: parseInt(paddingTop, 10),
			right: parseInt(paddingRight, 10),
			bottom: parseInt(paddingBottom, 10),
			left: parseInt(paddingLeft, 10),
		},
	};
};

const defaultRect = {
	left: 0,
	top: 0,
	height: 0,
	width: 0,
};

export const getRect: TMethodGetRect = (
	element,
	opts = { margin: null, padding: null },
) => {
	const includeMargin = !!opts?.margin;
	const excludePadding = !opts?.padding;

	if (!element) {
		return defaultRect;
	}

	const newSourceRect = element.getBoundingClientRect();
	const {
		pageXOffset,
		pageYOffset,
	}: { pageYOffset: number, pageXOffset: number } = window;

	const offsets = {
		top: pageYOffset,
		right: pageXOffset,
		bottom: pageYOffset,
		left: pageXOffset,
	};

	if (includeMargin || excludePadding) {
		const { margin, padding } = getStyles(element);

		if (includeMargin) {
			offsets.top -= margin.top;
			offsets.right += margin.right;
			offsets.bottom += margin.bottom;
			offsets.left -= margin.left;
		}

		if (excludePadding) {
			offsets.top += padding.top;
			offsets.right -= padding.right;
			offsets.bottom -= padding.bottom;
			offsets.left += padding.left;
		}
	}

	const top = newSourceRect.top + offsets.top;
	const right = newSourceRect.right + offsets.right;
	const bottom = newSourceRect.bottom + offsets.bottom;
	const left = newSourceRect.left + offsets.left;

	return {
		left,
		top,
		height: bottom - top,
		width: right - left,
	};
};

const useRect: TMethodUseRect = (props, isUpdateLocked = false) => {
	const { opts, currentRef, renderKey } = props;
	const [rect, setRect] = React.useState(() => getRect(currentRef.current, opts));

	const updateRect = React.useCallback(() => {
		setRect(getRect(currentRef.current, opts));
	}, [currentRef, opts]);

	React.useLayoutEffect(() => {
		if (isUpdateLocked) return;
		updateRect();
	}, [currentRef, opts, isUpdateLocked, renderKey, updateRect]);

	return rect;
};

export default useRect;
