// @flow

const getScrollbarWidth = () => {
	// for server
	if (typeof document === 'undefined') return 0;
	// return window.innerWidth - (document.documentElement?.clientWidth ?? 0);

	// Creating invisible container
	const outer = document.createElement('div');
	outer.style.visibility = 'hidden';
	outer.style.overflow = 'scroll'; // forcing scrollbar to appear
	if (!document.body) return 0;
	document.body.appendChild(outer);

	// Creating inner element and placing it in the container
	const inner = document.createElement('div');
	outer.appendChild(inner);

	// Calculating difference between container's full width and the child width
	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

	// Removing temporary elements from the DOM
	if (!outer.parentNode) return 0;
	outer.parentNode.removeChild(outer);

	return scrollbarWidth;
};

export default getScrollbarWidth;
