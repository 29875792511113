// @flow
import styled from '@emotion/styled';
import type { TSx } from '@graphite/types';

import { Flex } from '../Box';

const paddings = {
	md: '0 12px',
	sm: '0 9px',
};

const Toolbar: React$ComponentType<{|
	children: React$Node,
	variant?: 'sm' | 'md',
	sx?: ?TSx,
|}> = styled(Flex)`
	background-color: #fff;
	border-radius: 21px;
	box-shadow: ${({ theme }) => theme.shadows.sm};
	padding: ${({ variant = 'md' }) => paddings[variant]};
	align-items: center;
	flex-shrink: 0;
`;

export default Toolbar;
