// @flow
import styled from '@emotion/styled';
import _ from 'lodash/fp';

const LoaderIcon: React$ComponentType<{|
	color?: string,
	size?: number,
|}> = styled.div`
	border: 2px solid rgba(0, 0, 0, 0);
	border-left-color: ${({ color, theme }) =>
		_.get(color, theme.colors) || theme.colors.text.oncolor};
	border-right-color: ${({ color, theme }) =>
		_.get(color, theme.colors) || theme.colors.text.oncolor};
	border-radius: 50%;
	height: ${({ size }) => size || 18}px;
	width: ${({ size }) => size || 18}px;

	animation-name: rotate-s-loader;
	animation-iteration-count: infinite;
	animation-duration: 1s;
	animation-timing-function: linear;

	@keyframes rotate-s-loader {
		from {
			transform: rotate(0);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;
export default LoaderIcon;
