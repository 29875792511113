// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';
import Controls from '../Controls';
import type { TMinimalPropsWithControls, TPropsControl } from '../constants/types';

const withControls = <
	TProps: TMinimalPropsWithControls,
	WrapedComponent: React$ComponentType<$Diff<TProps, { controls: TPropsControl }>>,
>(
	Component: WrapedComponent,
): React$ComponentType<TProps> => {
	const WithControls = (props: TProps) => {
		const {
			currentDevice,
			gridspec,
			colorspec,
			effectspec,
			widgetspec,
			controls,
			data,
			originId,
			containerId,
			instanceId,
			hovered,
			dispatch,
			dragHandler,
			insertImage,
			removeImage,
			resetImage,
			images,
			uploads,
		} = props;

		return (
			<>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} />
				<Controls
					currentDevice={currentDevice}
					gridspec={gridspec}
					colorspec={colorspec}
					effectspec={effectspec}
					widgetspec={widgetspec}
					dispatch={dispatch}
					data={data}
					containerId={containerId}
					instanceId={instanceId}
					originId={originId}
					controls={controls}
					hovered={hovered}
					dragHandler={dragHandler}
					insertImage={insertImage}
					resetImage={resetImage}
					removeImage={removeImage}
					images={images}
					uploads={uploads}
				/>
			</>
		);
	};

	WithControls.displayName = `withControls(${getDisplayName(Component)})`;

	return React.memo(WithControls);
};

export default withControls;
