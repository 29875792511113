/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable flowtype/require-valid-file-annotation */

export default () => {
	if (process.env.NODE_ENV === 'production') {
		// Yandex.Metrika counter
		(function(m, e, t, r, i, k, a) {
			m[i] =
				m[i] ||
				function() {
					(m[i].a = m[i].a || []).push(arguments);
				};
			m[i].l = 1 * new Date();
			(k = e.createElement(t)),
				(a = e.getElementsByTagName(t)[0]),
				(k.async = 1),
				(k.src = r),
				a.parentNode.insertBefore(k, a);
		})(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

		ym(62811463, 'init', {
			clickmap: true,
			trackLinks: true,
			accurateTrackBounce: true,
			webvisor: true,
		});
		// /Yandex.Metrika counter
	}
};
