// @flow
import React from 'react';
import PopperJS from 'popper.js';
import { DraggableCore } from 'react-draggable';
import { zIndices } from '@graphite/constants';
import type { TSx } from '@graphite/types';

import getScrollbarWidth from '../libs/get-scrollbar-width';

import Box from '../Box';
import Portal from '../Portal';

type TDragPosition = $ReadOnly<{| top: number, left: number |}>;

type TProps = $ReadOnly<{|
	children: React$Node,
	isOpen: boolean,
	anchorEl: {| current: ?React$ElementRef<any> |},
	opacity?: ?number,
	zIndex?: number,
	offsetTop?: number,
	offsetLeft?: number,
	isFixed?: boolean,
	dragSelector?: ?string,
	placement?: 'top' | 'bottom' | 'right' | 'left',
	className?: string,
|}>;

const DEFAULT_POSITION: TDragPosition = { top: 0, left: 0 };
const MIN_LEFT: number = 12;
const MIN_RIGHT: number = MIN_LEFT + getScrollbarWidth();
const MIN_BOTTOM: number = 12;
const MIN_TOP: number = 12 + 54;

export const overlayOpenSx: TSx = {
	position: 'fixed',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	zIndex: 500,
	cursor: 'grabbing',
};

export const overlayClosedSx: TSx = {
	display: 'none',
};

const Popper = ({
	children,
	isOpen,
	anchorEl,
	opacity: opacityOverride = null,
	zIndex = zIndices.popup,
	offsetTop = 0,
	offsetLeft = 0,
	isFixed = false,
	dragSelector = '',
	placement = 'bottom',
	className,
}: TProps) => {
	const boxEl = React.useRef();
	const [position, setPosition] = React.useState<TDragPosition>(() => DEFAULT_POSITION);
	const [drag, setDrag] = React.useState<TDragPosition>(() => DEFAULT_POSITION);
	const [opacity, setOpacity] = React.useState<number>(0);
	const [isDragActive, setDragActive] = React.useState<boolean>(false);

	const popperRef = React.useRef();
	React.useEffect(() => {
		if (!isOpen || !anchorEl.current || !boxEl.current) {
			setOpacity(0);
			if (popperRef.current) popperRef.current.destroy();
			return;
		}

		window.requestAnimationFrame(() => {
			if (!boxEl.current) {
				return;
			}
			const { height, width } = boxEl.current.getBoundingClientRect();

			if (!isOpen || !anchorEl.current || !boxEl.current) {
				return;
			}
			popperRef.current = new PopperJS(anchorEl.current, boxEl.current, {
				placement,
				positionFixed: isFixed,
				modifiers: {
					applyStyle: { enabled: false },
					applyReactStyle: {
						enabled: true,
						fn: data => {
							const src = data.offsets[isFixed ? 'reference' : 'popper'];
							setPosition({
								left: Math.min(
									Math.max(src.left + offsetLeft, MIN_LEFT),
									window.innerWidth - MIN_RIGHT - width,
								),
								top: isFixed
									? Math.min(
											Math.max(src.top + offsetTop, MIN_TOP),
											window.innerHeight - MIN_BOTTOM - height,
									  )
									: src.top + offsetTop,
							});

							setOpacity(1);

							return data;
						},
						order: 900,
					},
				},
			});
		});

		// eslint-disable-next-line consistent-return
		return () => {
			if (popperRef.current) popperRef.current.destroy();
		};
	}, [
		isFixed,
		popperRef,
		boxEl,
		setPosition,
		setOpacity,
		isOpen,
		anchorEl,
		offsetTop,
		offsetLeft,
		placement,
	]);

	const onStart = React.useCallback(() => setDragActive(true), []);

	const onDrag = React.useCallback(
		data => {
			const { movementX, movementY } = data;
			// eslint-disable-next-line no-shadow
			setDrag(position => ({
				left: position.left + movementX,
				top: position.top + movementY,
			}));
		},
		[setDrag],
	);

	const onStop = React.useCallback(() => setDragActive(false), []);

	const styles = React.useMemo(
		() => ({
			position: isFixed ? 'fixed' : 'absolute',
			left: position.left + drag.left,
			top: position.top + drag.top,
			transition: 'opacity 0.15s ease-out',
			zIndex,
			opacity: opacityOverride === null ? opacity : opacityOverride,
		}),
		[isFixed, position, drag, zIndex, opacity, opacityOverride],
	);

	const preventMenu = React.useCallback((e: SyntheticMouseEvent<EventTarget>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	// ToDo решает проблемы в производителстью
	// Возможно если переписать попер на нормаьный попап, то не нужно будет
	if (!isOpen) return null;

	return (
		<Portal>
			<Box
				sx={isDragActive ? overlayOpenSx : overlayClosedSx}
				onContextMenu={preventMenu}
			/>
			{(dragSelector && (
				<DraggableCore
					handle={dragSelector}
					onStart={onStart}
					onDrag={onDrag}
					onStop={onStop}
				>
					<Box
						ref={boxEl}
						style={styles}
						display={isOpen ? 'block' : 'none'}
						className={className}
					>
						{children}
					</Box>
				</DraggableCore>
			)) || (
				<Box
					ref={boxEl}
					style={styles}
					display={isOpen ? 'block' : 'none'}
					className={className}
				>
					{children}
				</Box>
			)}
		</Portal>
	);
};

export default React.memo<TProps>(Popper);
