// @flow
import React from 'react';
import { Flex, Box } from '@graphite/uneon';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import type {
	TWidget,
	TWidgetDiff,
	TPositionValue,
	TWidgetBoxBreakpoint,
	TOffsetDevice,
	TMarginDevice,
	TPaddingDevice,
	TGridBreakpointName,
} from '@graphite/types';

import { closestDeviceWithKey } from '@graphite/selectors';
import { getRect } from 'Editor/libs/use-rect';

type TProps = $ReadOnly<{|
	data: TWidget,
	position: TPositionValue,
	currentDevice: TGridBreakpointName,
	save: TWidgetDiff => void,
	currentRef?: {| current: ?HTMLDivElement |},
	typePosition: 'padding' | 'margin',
|}>;

const SMALL_BOX_SIZE = 24;
const SMALL_BOX_HALF = SMALL_BOX_SIZE / 2;
const MIN_DISTANCE = 5;
const MIN_DISTANCE_SQUARE = MIN_DISTANCE * MIN_DISTANCE;

const columnBaseSx = {
	flexDirection: 'column',
	alignItems: 'center',
	width: '84px',
	height: '84px',
	borderRadius: 'lg.all',
	borderStyle: 'solid',
	borderWidth: '2px',
	borderColor: 'bg.secondary',
	justifyContent: 'space-evenly',
};

const rowSx = {
	alignItems: 'center',
	justifyContent: 'space-evenly',
	width: '100%',
};

const stickSx = {
	position: 'absolute',
	backgroundColor: 'bg.secondary',
	pointerEvents: 'none',
};

const horizontalSx = {
	...stickSx,
	top: '11px',
	left: '4.5px',
	width: '15px',
	height: '2px',
};

const verticalSx = {
	...stickSx,
	top: '4.5px',
	left: '11px',
	width: '2px',
	height: '15px',
};

const smallBoxBaseSx = {
	width: `${SMALL_BOX_SIZE}px`,
	height: `${SMALL_BOX_SIZE}px`,
	position: 'relative',
};

const smallBoxHoverSx = {
	cursor: 'pointer',
	'&:hover > div': {
		backgroundColor: 'bg.accentplus',
	},
};

const centerBoxSx = {
	...smallBoxBaseSx,
	borderRadius: 'md.all',
	borderStyle: 'solid',
	borderWidth: '2px',
	borderColor: 'bg.secondary',
};

const SIDE_TOP = 0;
const SIDE_RIGHT = 1;
const SIDE_BOTTOM = 2;
const SIDE_LEFT = 3;

const opposites = {
	left: 'right',
	top: 'bottom',
	right: 'left',
	bottom: 'top',
};
const horSides = ['left', 'right'];
const vertSides = ['top', 'bottom'];

type TOffsetValues = $ReadOnly<{|
	top: number,
	right: number,
	bottom: number,
	left: number,
	centerx: number,
	centery: number,
	width: number,
	height: number,
|}>;

const getOffsetValues = (nodeIn: HTMLElement): TOffsetValues => {
	// Поднимаемся к ноде контейнера абсолюта: он через одну
	const nodeOut = nodeIn?.parentNode?.parentNode ?? null;

	// Берем 2 ректа: виджет=rectIn, контейнер=rectOut
	const rectIn = getRect(nodeIn);
	const rectOut = getRect(
		nodeOut && nodeOut instanceof nodeOut.ownerDocument.defaultView.HTMLElement
			? nodeOut
			: null,
		{ padding: true, margin: false },
	);
	/*
	 * Набор значений оффсета для всех возможных полей
	 * Например, если мы хотим прицепить виджет по `top`, то смещение - это разность
	 * между `top` внутреннего и внешнего виджетов.
	 * Для центральных координат `centerx, centery` значения вычисляются так, чтобы
	 * их можно было непосредственно подставить в `calc(50% + _)`.
	 */
	return {
		top: rectIn.top - rectOut.top,
		right: rectOut.left + rectOut.width - (rectIn.left + rectIn.width),
		bottom: rectOut.top + rectOut.height - (rectIn.top + rectIn.height),
		left: rectIn.left - rectOut.left,
		centerx: rectIn.left - rectOut.left - rectOut.width / 2,
		centery: rectIn.top - rectOut.top - rectOut.height / 2,
		width: rectIn.width,
		height: rectIn.height,
	};
};

function LockOn({
	data,
	position,
	save,
	currentDevice,
	currentRef,
	typePosition,
}: TProps) {
	const [centerHover, setCenterHover] = React.useState<'x' | 'y'>('x');
	const isAbsolute = position && position.includes('absolute');

	const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
		currentDevice,
		key: `box-${data._id}`,
	});
	const margins: TMarginDevice | TPaddingDevice = box[typePosition] || emptyObject;
	const offsets: TOffsetDevice = box.offset || emptyObject;

	const activeSides = React.useMemo(
		() =>
			['top', 'right', 'bottom', 'left'].map(side => {
				if (!isAbsolute) {
					// 0 => off
					return !!margins[side];
				}
				// 0 is fine too
				return offsets[side] !== undefined;
			}),
		[isAbsolute, margins, offsets],
	);

	const sideSx = React.useMemo(
		() =>
			[verticalSx, horizontalSx, verticalSx, horizontalSx].map((baseSx, i) => {
				if (!activeSides[i]) {
					return baseSx;
				}
				return { ...baseSx, backgroundColor: 'bg.accent' };
			}),
		[activeSides],
	);

	const centerVertSx = React.useMemo(() => {
		if (isAbsolute && offsets.centery !== undefined) {
			return { ...verticalSx, backgroundColor: 'bg.accent', zIndex: 1 };
		}
		return verticalSx;
	}, [isAbsolute, offsets.centery]);
	const centerHorSx = React.useMemo(() => {
		if (isAbsolute && offsets.centerx !== undefined) {
			return { ...horizontalSx, backgroundColor: 'bg.accent', zIndex: 1 };
		}
		return horizontalSx;
	}, [isAbsolute, offsets.centerx]);

	const clickSide = React.useMemo(
		() =>
			['top', 'right', 'bottom', 'left'].map(side => {
				if (!isAbsolute) {
					return () => {};
				}
				return () => {
					if (!currentRef || !currentRef.current) {
						return;
					}
					const values = getOffsetValues(currentRef.current);
					let nextOffsets: TOffsetDevice = offsets;
					if (offsets[side] !== undefined) {
						nextOffsets = (_.unset(side, nextOffsets): TOffsetDevice);
						if (
							horSides.includes(side) &&
							offsets[opposites[side]] === undefined
						) {
							nextOffsets = ({
								...nextOffsets,
								centerx: values.centerx,
								width: values.width,
							}: TOffsetDevice);
						} else if (
							vertSides.includes(side) &&
							offsets[opposites[side]] === undefined
						) {
							nextOffsets = ({
								...nextOffsets,
								centery: values.centery,
								height: values.height,
							}: TOffsetDevice);
						}
					} else {
						nextOffsets = (_.set(
							side,
							values[side],
							nextOffsets,
						): TOffsetDevice);
						if (horSides.includes(side)) {
							nextOffsets = (_.unset(
								'centerx',
								nextOffsets,
							): TOffsetDevice);
						} else if (vertSides.includes(side)) {
							nextOffsets = (_.unset(
								'centery',
								nextOffsets,
							): TOffsetDevice);
						}
					}

					// Глубокий `_.set` не подходит, т.к. девайса там могло не быть:
					// В `box` ближайший девайс, а записываем изменения именно в указанный
					save({
						box: {
							...data.box,
							[`${currentDevice}`]: _.set('offset', nextOffsets, box),
						},
					});
				};
			}),
		[box, currentDevice, currentRef, data.box, isAbsolute, offsets, save],
	);

	const moveCenter = React.useCallback(
		e => {
			const x = Math.abs(e.nativeEvent.offsetX - SMALL_BOX_HALF);
			const y = Math.abs(e.nativeEvent.offsetY - SMALL_BOX_HALF);
			if (x * x + y * y < MIN_DISTANCE_SQUARE) {
				return;
			}

			if (x > y && centerHover !== 'x') {
				setCenterHover('x');
			} else if (x < y && centerHover !== 'y') {
				setCenterHover('y');
			}
		},
		[centerHover],
	);

	const clickCenter = React.useCallback(
		e => {
			if (!currentRef || !currentRef.current) {
				return;
			}
			const values = getOffsetValues(currentRef.current);
			const x = Math.abs(e.nativeEvent.offsetX - SMALL_BOX_HALF);
			const y = Math.abs(e.nativeEvent.offsetY - SMALL_BOX_HALF);

			let nextOffsets: TOffsetDevice = offsets;
			if (x > y) {
				if (offsets.centerx !== undefined) {
					nextOffsets = (_.unset('centerx', nextOffsets): TOffsetDevice);
					nextOffsets = (_.set(
						'left',
						values.left,
						nextOffsets,
					): TOffsetDevice);
				} else {
					nextOffsets = (_.unset('left', nextOffsets): TOffsetDevice);
					nextOffsets = (_.unset('right', nextOffsets): TOffsetDevice);
					nextOffsets = (_.set(
						'centerx',
						values.centerx,
						nextOffsets,
					): TOffsetDevice);
				}
				nextOffsets = (_.set('width', values.width, nextOffsets): TOffsetDevice);
			} else {
				if (offsets.centery !== undefined) {
					nextOffsets = (_.unset('centery', nextOffsets): TOffsetDevice);
					nextOffsets = (_.set('top', values.top, nextOffsets): TOffsetDevice);
				} else {
					nextOffsets = (_.unset('top', nextOffsets): TOffsetDevice);
					nextOffsets = (_.unset('bottom', nextOffsets): TOffsetDevice);
					nextOffsets = (_.set(
						'centery',
						values.centery,
						nextOffsets,
					): TOffsetDevice);
				}
				nextOffsets = (_.set(
					'height',
					values.height,
					nextOffsets,
				): TOffsetDevice);
			}

			// Глубокий `_.set` не подходит, т.к. девайса там могло не быть:
			// В `box` ближайший девайс, а записываем изменения именно в указанный
			save({
				box: {
					...data.box,
					[`${currentDevice}`]: _.set('offset', nextOffsets, box),
				},
			});
		},
		[currentRef, offsets, save, data.box, currentDevice, box],
	);

	const smallBoxSx = React.useMemo(() => {
		if (!isAbsolute) {
			return smallBoxBaseSx;
		}
		return { ...smallBoxBaseSx, ...smallBoxHoverSx };
	}, [isAbsolute]);

	const smallBoxCenterSx = React.useMemo(() => {
		if (!isAbsolute) {
			return centerBoxSx;
		}
		if (!centerHover) {
			return { ...smallBoxBaseSx, cursor: 'pointer' };
		}
		if (centerHover === 'x') {
			return {
				...smallBoxBaseSx,
				cursor: 'pointer',
				'&:hover > div:first-of-type': {
					backgroundColor: 'bg.accentplus',
					zIndex: 1,
				},
			};
		}
		if (centerHover === 'y') {
			return {
				...smallBoxBaseSx,
				cursor: 'pointer',
				'&:hover > div:last-child': {
					backgroundColor: 'bg.accentplus',
					zIndex: 1,
				},
			};
		}
		return { ...smallBoxBaseSx, ...smallBoxHoverSx };
	}, [centerHover, isAbsolute]);

	const columnSx = React.useMemo(() => {
		if (isAbsolute) {
			return columnBaseSx;
		}
		return { ...columnBaseSx, borderStyle: 'dashed' };
	}, [isAbsolute]);

	return (
		<Flex sx={columnSx}>
			<Box sx={smallBoxSx} onClick={clickSide[SIDE_TOP]}>
				<Box sx={sideSx[SIDE_TOP]} />
			</Box>
			<Flex sx={rowSx}>
				<Box sx={smallBoxSx} onClick={clickSide[SIDE_LEFT]}>
					<Box sx={sideSx[SIDE_LEFT]} />
				</Box>
				{(!isAbsolute && <Box sx={smallBoxCenterSx} />) || (
					<Box
						sx={smallBoxCenterSx}
						onClick={clickCenter}
						onMouseMove={moveCenter}
					>
						<Box sx={centerHorSx} />
						<Box sx={centerVertSx} />
					</Box>
				)}
				<Box sx={smallBoxSx} onClick={clickSide[SIDE_RIGHT]}>
					<Box sx={sideSx[SIDE_RIGHT]} />
				</Box>
			</Flex>
			<Box sx={smallBoxSx} onClick={clickSide[SIDE_BOTTOM]}>
				<Box sx={sideSx[SIDE_BOTTOM]} />
			</Box>
		</Flex>
	);
}

export default React.memo<TProps>(LockOn);
