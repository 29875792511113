// @flow

import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Text, Icon, Link } from '@graphite/uneon';

import ButtonPublish from './ButtonPublish';

type TProps = $ReadOnly<{|
	goToBuider: () => void,
	publishUrl: string,
|}>;

const flexLeftSx = {
	flexBasis: '50%',
	alignItems: 'center',
};

const flexRightSx = {
	flexBasis: '50%',
	alignItems: 'center',
	justifyContent: 'flex-end',
};

const PublishedLink = styled(Text)`
	color: ${({ theme, linkAvailable }) =>
		linkAvailable ? theme.colors.text.primary : theme.colors.text.secondary};
`;

const Back = styled(Button)`
	display: flex;
	align-items: center;
`;

const iconSx = {
	marginRight: '9px',
};

const linkSx = {
	marginRight: '12px',
};

function Preview({ goToBuider, publishUrl }: TProps) {
	const { t } = useTranslation();

	return (
		<>
			<Flex sx={flexLeftSx}>
				<Back variant="primaryalt.rounded.sm" onClick={goToBuider}>
					<Icon name="arrow-top" size="xxsm" rotation={-90} sx={iconSx} />
					{t('Builder')}
				</Back>
			</Flex>
			<Flex sx={flexRightSx}>
				<PublishedLink linkAvailable={!!publishUrl} variant="bodysm">
					{publishUrl ? (
						<Link
							href={publishUrl}
							rel="noopener noreferrer"
							target="_blank"
							sx={linkSx}
						>
							{publishUrl.replace('http://', '')}
						</Link>
					) : (
						t('Not published yet')
					)}
				</PublishedLink>
				<ButtonPublish publishUrl={publishUrl} />
			</Flex>
		</>
	);
}

export default React.memo<TProps>(Preview);
