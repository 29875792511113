// @flow
import React from 'react';
import { ToolbarBtn, PopupWhite, Section, Text } from '@graphite/uneon';
import { Params as ListParams } from '@graphite/lists';

import type { TWidgetButton } from '../constants/types';

type TProps = $ReadOnly<{|
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
	data: TWidgetButton,
	save: (string, string | Object) => void,
|}>;

const titleSx = {
	marginBottom: '18px',
};

const CONTROL_NAME = 'gear';

const paramsShowOnly = [
	{
		title: 'Show',
		key: 'isShown',
		kind: 'switch',
		info: {},
	},
];

const paramLabel = {
	title: 'Label',
	key: 'label',
	kind: 'string',
	info: { maxLength: 140 },
};

const paramsIcon = [
	{
		title: 'Name',
		key: 'name',
		kind: 'string',
		info: { maxLength: 140 },
	},
	{
		title: 'Position',
		key: 'position',
		kind: 'select',
		info: {
			list: {
				items: [
					{ name: 'left', label: 'Left' },
					{ name: 'right', label: 'Right' },
				],
			},
		},
	},
];

const Settings = ({ openedPanel, setOpenedPanel, save, data }: TProps) => {
	const btnEl = React.useRef(null);
	const handleToogleOpen = React.useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(openedPanel =>
				openedPanel === CONTROL_NAME ? null : CONTROL_NAME,
			);
		},
		[setOpenedPanel],
	);

	const isActive = openedPanel === CONTROL_NAME;

	/**
		Text
	 */
	const paramListText = React.useMemo(() => {
		if (!data.text || !data.text.isShown) {
			return paramsShowOnly;
		}
		return [...paramsShowOnly, paramLabel];
	}, [data.text]);

	const paramSourceText = React.useMemo(
		() => ({
			isShown: data.text && data.text.isShown ? 'true' : '',
			label: (data.text && data.text.label) || '',
		}),
		[data.text],
	);

	const changeParamText = React.useCallback(
		(key, value) => {
			save('text', {
				...data.text,
				[key]: value,
			});
		},
		[save, data.text],
	);

	/**
		Icon
	 */
	const paramListIcon = React.useMemo(() => {
		if (!data.icon || !data.icon.isShown) {
			return paramsShowOnly;
		}
		return [...paramsShowOnly, ...paramsIcon];
	}, [data.icon]);

	const paramSourceIcon = React.useMemo(
		() => ({
			isShown: data.icon && data.icon.isShown ? 'true' : '',
			name: (data.icon && data.icon.name) || '',
			position: (data.icon && data.icon.position) || 'left',
		}),
		[data.icon],
	);

	const changeParamIcon = React.useCallback(
		(key, value) => save('icon', { ...data.icon, [key]: value }),
		[save, data.icon],
	);

	return (
		<>
			<ToolbarBtn
				onMouseDown={handleToogleOpen}
				iconName="gear"
				ref={btnEl}
				isActive={isActive}
			/>

			<PopupWhite
				isOpen={isActive}
				anchorEl={btnEl}
				offsetTop={10}
				onClose={handleToogleOpen}
				isFixed
				mutex="settings"
			>
				{isActive && (
					<>
						<Text variant="title4" color="text.primaryalt" sx={titleSx}>
							Settings
						</Text>
						<Section label="Text">
							<ListParams
								listName="text"
								paramSource={paramSourceText}
								paramList={paramListText}
								unit={1}
								onChange={changeParamText}
							/>
						</Section>
						<Section label="Icon">
							<ListParams
								listName="icon"
								paramSource={paramSourceIcon}
								paramList={paramListIcon}
								unit={1}
								onChange={changeParamIcon}
							/>
						</Section>
					</>
				)}
			</PopupWhite>
		</>
	);
};

export default React.memo<TProps>(Settings);
