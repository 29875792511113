// @flow
import React from 'react';
import type { TWidgetDiff } from '@graphite/types';
import { editWidget } from 'Editor/ducks/widgets';
import ToolbarLayoutButton from 'Editor/Buttons/Toolbar/Layout';

import type { TConnectPropsWithControls } from '../constants/types';

const Controls = ({
	gridspec,
	position,
	currentDevice,
	dispatch,
	data,
	instanceId,
	originId,
	currentRef,
}: TConnectPropsWithControls) => {
	const { _id } = data;

	const [openedPanel, setOpenedPanel] = React.useState(null);

	const saveDiff = React.useCallback(
		(diff: TWidgetDiff) => {
			if (!originId) return;
			dispatch(editWidget(_id, instanceId, originId, diff));
		},
		[_id, instanceId, originId, dispatch],
	);

	return (
		<>
			<ToolbarLayoutButton
				openedPanel={openedPanel}
				setOpenedPanel={setOpenedPanel}
				save={saveDiff}
				data={data}
				position={position}
				currentRef={currentRef}
				unit={gridspec.unit}
				currentDevice={currentDevice}
			/>
		</>
	);
};

export default React.memo<TConnectPropsWithControls>(Controls);
