// @flow
import React from 'react';
import Draggable from 'react-draggable';
import { Box, Text, Flex } from 'rebass';

type TProps = $ReadOnly<{|
	min: number,
	max: number,
	step?: number,
	title?: string,
	value: number,
	unit?: string,
	onChange?: ?(number) => void,
	onPreview?: ?(number) => void,
|}>;

const containerStyle = {
	height: '50px',
	position: 'relative',
	userSelect: 'none',
};

const handleStyle = {
	width: '16px',
	height: '16px',
	cursor: 'pointer',
	borderRadius: 'rounded.all',
};

const trackStyle = {
	width: '100%',
	height: '2px',
	position: 'absolute',
	bottom: '10px',
};

const fillStyle = {
	height: '2px',
	position: 'absolute',
	bottom: '10px',
};

const boundStyle = {
	height: '2px',
	position: 'absolute',
	bottom: '17px',
	left: '-8px',
	right: '8px',
};

const titleStyle = {
	position: 'absolute',
	top: '3px',
	left: 0,
};

const announceStyle = {
	position: 'absolute',
	top: '3px',
	right: 0,
};

const valueTextStyle = {
	marginLeft: '4px',
};

function SliderSimple({
	min,
	max,
	step = 1,
	title = '',
	value,
	unit = '',
	onChange = null,
	onPreview = null,
}: TProps) {
	if (max <= min) {
		throw new Error('SliderSimple: max <= min.');
	}

	if (value < min) {
		throw new Error('SliderSimple: value < min.');
	}

	if (value > max) {
		throw new Error('SliderSimple: value > max.');
	}

	const containerRef = React.useRef();

	const [width, setWidth] = React.useState(0);
	const [ownValue, setOwnValue] = React.useState(value);

	React.useEffect(() => setOwnValue(value), [value]);

	React.useEffect(() => {
		window.requestAnimationFrame(() => {
			if (containerRef.current) {
				setWidth(containerRef.current.getBoundingClientRect().width);
			}
		});
	}, []);

	const stepPixels = Math.ceil((width / (max - min)) * step);

	const onDrag = React.useCallback(
		(e, { x }) => {
			const newValue = min + step * Math.round(x / stepPixels);
			setOwnValue(newValue);
			if (onPreview) {
				onPreview(newValue);
			}
		},
		[onPreview, min, step, stepPixels],
	);

	const onStop = React.useCallback(
		(e, { x }) => {
			const newValue = min + step * Math.round(x / stepPixels);
			setOwnValue(newValue);
			if (onChange) {
				onChange(newValue);
			}
		},
		[onChange, min, step, stepPixels],
	);

	const percent = (ownValue - min) / (max - min);
	const x = Math.floor(width * percent);

	const bounds = React.useMemo(() => ({ left: 0, right: width, top: 0, bottom: 0 }), [
		width,
	]);
	const grid = React.useMemo(() => [stepPixels, 1], [stepPixels]);
	const position = React.useMemo(() => ({ x, y: 0 }), [x]);

	return (
		<Box sx={containerStyle} ref={containerRef}>
			<Box sx={titleStyle}>
				<Text variant="bodysm" color="spec.darkblue40">
					{title}
				</Text>
			</Box>
			<Flex sx={announceStyle}>
				<Text variant="headlinesm" color="spec.darkblue40" sx={valueTextStyle}>
					{ownValue}
				</Text>
				<Text variant="headlinesm" color="spec.darkblue40" sx={valueTextStyle}>
					{unit}
				</Text>
			</Flex>
			<Box sx={trackStyle} backgroundColor="spec.lightblue20" />
			<Box sx={fillStyle} width={x} backgroundColor="spec.blue40" />
			<Box sx={boundStyle}>
				<Draggable
					axis="x"
					bounds={bounds}
					grid={grid}
					position={position}
					onDrag={onDrag}
					onStop={onStop}
				>
					<Box backgroundColor="spec.blue40" sx={handleStyle} />
				</Draggable>
			</Box>
		</Box>
	);
}

export default React.memo<TProps>(SliderSimple);
