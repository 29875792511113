// @flow

import React, { memo, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Flex, Box, Text } from 'rebass';

import Icon from '../Icon';

type TSectionVariant = 'headlinemd' | 'bodysm';

type TProps = $ReadOnly<{|
	children?: ?React$Node,
	additional?: ?React$Node,
	buttons?: ?React$Node,
	title?: string,
	variant?: TSectionVariant,
|}>;

const titleSx = {
	cursor: 'pointer',
};

const sectionBoxStyle = {
	marginBottom: '12px',
};

const titleVariantStyles = {
	headlinemd: {
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '21px',
	},
	bodysm: {
		marginBottom: '6px',
		alignItems: 'center',
	},
};

const additionalBoxStyle = {
	transitionTimingFunction: 'cubic-bezier(.25, .46, .45, .94)',
	transitionDuration: '0.25s',
	transitionProperty: 'height',
	overflow: 'hidden',
};

const variantColors = {
	headlinemd: 'text.primaryalt',
	bodysm: 'text.secondary',
};

function Section({
	children = null,
	additional = null,
	buttons = null,
	title = '',
	variant = 'headlinemd',
}: TProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [additionalHeight, setAdditionalHeight] = useState(0);

	const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

	const additionalRef = useRef();

	const additionalDynamicStyle = useMemo(
		() => ({
			height: isOpen ? `${additionalHeight}px` : 0,
		}),
		[isOpen, additionalHeight],
	);

	useEffect(() => {
		window.requestAnimationFrame(() => {
			if (additionalRef.current) {
				setAdditionalHeight(additionalRef.current.getBoundingClientRect().height);
			}
		});
	}, []);

	return (
		<Box sx={sectionBoxStyle}>
			<Flex sx={titleVariantStyles[variant]}>
				<Flex sx={titleSx} onClick={toggleOpen}>
					<Text variant={variant} color={variantColors[variant]}>
						{title}
					</Text>
					{additional && (
						<Icon
							name="expand-small-top"
							iconSize={18}
							rotation={isOpen ? 180 : 90}
							colors="tertiaryflat"
						/>
					)}
				</Flex>
				{buttons && <Flex>{buttons}</Flex>}
			</Flex>
			{children}
			{additional && (
				<Box sx={additionalBoxStyle} style={additionalDynamicStyle}>
					<Box ref={additionalRef}>{additional}</Box>
				</Box>
			)}
		</Box>
	);
}

export default memo<TProps>(Section);
