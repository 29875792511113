// @flow
import React, { memo, useMemo } from 'react';
import { Box } from 'rebass';
import type { TSx } from '@graphite/types';

type TProps = $ReadOnly<{|
	color: string,
	size: number,
	sx?: TSx,
|}>;

function Checkers({ color, size, sx }: TProps) {
	const checkersDynamicStyle = useMemo(
		() => ({
			backgroundImage: [
				`linear-gradient(45deg, ${color} 25%, transparent 25%)`,
				`linear-gradient(-45deg, ${color} 25%, transparent 25%)`,
				`linear-gradient(45deg, transparent 75%, ${color} 75%)`,
				`linear-gradient(-45deg, transparent 75%, ${color} 75%)`,
			].join(','),
			backgroundSize: `${size * 2}px ${size * 2}px`,
			backgroundPosition: `0 0, 0 ${size}px, ${size}px -${size}px, -${size}px 0px`,
		}),
		[size, color],
	);

	return <Box style={checkersDynamicStyle} sx={sx} />;
}

Checkers.defaultProps = {
	sx: null,
};

export default memo<TProps>(Checkers);
