// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash/fp';
import { Box } from '@graphite/uneon';

import dndContext from 'Widget/libs/dnd/dndContext';
import useRect from 'Editor/libs/use-rect';
import type { TOptions } from './types';

type TProps = $ReadOnly<{|
	currentRef: {| current: ?HTMLElement |},
	symbioteRef: {| current: ?HTMLElement |},
	ownProps: mixed,
	opts: TOptions,
	isHovered: boolean,
	children: React$Element<React$ElementType>,
	renderKey: ?number,
	className: ?string,
|}>;

const boxSx = {
	pointerEvents: 'none',
	position: 'absolute',
};

const Portal = (props: TProps) => {
	const { opts, symbioteRef, ownProps, isHovered, className } = props;
	const { children, ...rectProps } = props;
	const { symbioteRoot } = opts;

	const [{ isFliping }] = React.useContext(dndContext);

	const isVisibleSymbiote = symbioteRef?.current?.children.length !== 0;
	// Fixme
	const isActive = isHovered || true || isVisibleSymbiote;
	const isUpdateLocked = isFliping || !isActive;
	const newRect = useRect(rectProps, isUpdateLocked);

	if (!symbioteRoot) return null;
	const node = symbioteRoot.current;
	if (!node) return null;

	return ReactDOM.createPortal(
		<Box
			sx={boxSx}
			data-kind={_.get('data.kind', ownProps)}
			data-id={_.get('data._id', ownProps)}
			style={newRect}
			ref={symbioteRef}
			className={className}
		>
			{children}
		</Box>,
		node,
	);
};

export default React.memo<TProps>(Portal);
