// @flow
import React from 'react';
import emptyObject from 'empty/object';
import { Box, Flex, Text, Icon } from '@graphite/uneon';
import { zIndices, transitions } from '@graphite/constants';
import { useTranslation } from 'react-i18next';

import ResizeCols from 'Widget/libs/resize-cols';
import useDefaultDevice from 'Editor/libs/use-default-device';
import { editWidget } from 'Editor/ducks/widgets';

import Settings from 'Widget/Settings';

import type { TPropsControls } from '../constants/types';

import Resize from './Resize';
import Actions from './Actions';
import Design from './Design';

const hiddenSx = {
	opacity: 0,
	pointerEvents: 'none',
	transitionDuration: transitions.columnControls.hideDuration,
	transitionTimingFunction: transitions.columnControls.hideTiming,
	transitionDelay: transitions.columnControls.hideDelay,
	transitionProperty: 'opacity',
};
const visibleSx = {
	opacity: 1,
	pointerEvents: 'none',
	transitionDuration: transitions.columnControls.showDuration,
	transitionTimingFunction: transitions.columnControls.showTiming,
	transitionDelay: transitions.columnControls.showDelay,
	transitionProperty: 'opacity',
};

const columnSx = {
	position: 'absolute',
	right: '0',
	top: '0',
	bottom: '0',
	left: '0',
	justifyContent: 'center',
	alignItems: 'center',
	flexWrap: 'nowrap',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: 'spec.blue10',
	zIndex: zIndices.columnControls,
	':hover': {
		zIndex: zIndices.columnControlsHover,
	},
};

const resizeHandlerSx = {
	position: 'absolute',
	height: '11px',
	width: '11px',
	borderRadius: 'rounded.all',
	backgroundColor: '#fff',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: 'spec.blue10',
	boxShadow: 'xsm',
};

const resizeHandlerLeftSx = {
	...resizeHandlerSx,
	left: '-6px',
};

const resizeHandlerRightSx = {
	...resizeHandlerSx,
	right: '-6px',
};

// FixMe переделать на Toolbar из uNeon, тогда большюу часть стилей можно будет выкинуть
// Но сначала нужно переделать Toolbar, чтобы он был на sx или variants, иначе
// сейчас неправильный порядок стилей

const handlerSx = {
	justifyContent: 'center',
	alignItems: 'center',
	flexWrap: 'nowrap',
	flexShrink: '0',

	alignSelf: 'flex-start',
	height: '30px',
	marginTop: '-16px',
	padding: '0 6px',

	backgroundColor: 'spec.blue10',
	boxShadow: 'smblue',
	borderRadius: '15px',
	pointerEvents: 'auto',
};

const toolbarBtnDragStyle = {
	cursor: 'grab',
};

const captionSx = {
	color: 'text.oncolor',
	margin: '0 9px',
	cursor: 'default',
	userSelect: 'none',
};

function Controls(props: TPropsControls): React$Node {
	const [
		{ deltaRange, delta, side, dragId, dragWidgetOriginalsize },
	] = React.useContext(ResizeCols);

	const { t } = useTranslation();

	const {
		currentDevice,
		gridspec,
		colorspec,
		effectspec,
		widgetspec,
		dispatch,
		data,
		containerId: containerInitialId,
		instanceId,
		originId,
		controls,
		hovered,
		dragHandler,
		insertImage,
		removeImage,
		resetImage,
		images,
		uploads,
	} = props;
	const containerId = containerInitialId || 'none';
	const [openedPanel, setOpenedPanel] = React.useState<?string>(null);
	const isDefaultDevice = currentDevice === useDefaultDevice();
	const { _id } = data;

	const isDrag = dragId === _id;
	const isHidden = controls === 'none' || controls === 'baby';
	const isActive = (hovered || isDrag || openedPanel !== null) && !isHidden;

	const [isMenuShow, setMenuShow] = React.useState(false);

	React.useEffect(() => {
		if (!isActive && openedPanel !== null) {
			setOpenedPanel(null);
		}
	}, [isActive, openedPanel]);

	React.useEffect(() => {
		if (isMenuShow && !isActive) setMenuShow(() => false);
	}, [isMenuShow, setMenuShow, isActive]);

	// ToDo: ёбта, напишите комментарий что здесь происходит!!!
	// ----
	// Предположение: при ресайзе колонки, ПРОТИВОПОЛОЖНАЯ сторона стыкуется на "0";
	//  сторона с которую тянут свободно плавает с "auto";
	//  далее позиция ресайза определяется шириной, которая зависит от текущей дельты.
	//  Начальное значение ширины берётся из контекста.
	const style = React.useMemo(() => {
		const width = dragWidgetOriginalsize;
		const sideReal = dragId === _id ? side : null;
		const [min, max] = deltaRange;

		let deltaX = 0;

		if (delta > min) {
			deltaX = delta;
			if (delta < max) {
				deltaX = delta;
			} else {
				deltaX = max;
			}
		} else {
			deltaX = min;
		}

		if (sideReal === 'right') {
			return {
				right: 'auto',
				left: '0',
				width: `${parseInt(width, 10) + deltaX}px`,
			};
		}

		if (sideReal === 'left') {
			return {
				left: 'auto',
				right: '0',
				width: `${parseInt(width, 10) + deltaX * -1}px`,
			};
		}

		return emptyObject;
	}, [dragWidgetOriginalsize, deltaRange, dragId, _id, side, delta]);

	// FixMe сделать по человечески
	// Чинит баг, с тем что можно ресайзить колонку когда выбран виджет
	const resizeStyle = React.useMemo(() => ({ display: isActive ? 'block' : 'none' }), [
		isActive,
	]);
	const handlerStyle = React.useMemo(
		() => ({ pointerEvents: isActive ? 'auto' : 'none' }),
		[isActive],
	);

	const save = React.useCallback(
		(name, value: any) => {
			if (!originId) return;
			dispatch(
				editWidget(_id, instanceId, originId, {
					[`${name}`]: value,
				}),
			);
		},
		[_id, instanceId, originId, dispatch],
	);

	return (
		<>
			<Box sx={isActive ? visibleSx : hiddenSx}>
				<Flex sx={columnSx} style={style}>
					<Box sx={resizeHandlerLeftSx} />
					<Box sx={resizeHandlerRightSx} />
					<Box style={resizeStyle}>
						<Resize id={_id} />
					</Box>
					<Flex sx={handlerSx} style={handlerStyle}>
						{isDefaultDevice && (
							// eslint-disable-next-line react/jsx-props-no-spreading
							<Box {...dragHandler} sx={toolbarBtnDragStyle}>
								<Icon
									size="sm"
									name="dots-horizontal-thin-6"
									colors="accent"
								/>
							</Box>
						)}
						<Text variant="captionlg" sx={captionSx}>
							{t('Column')}
						</Text>
						<Design
							currentDevice={currentDevice}
							colorspec={colorspec}
							effectspec={effectspec}
							gridspec={gridspec}
							widgetspec={widgetspec}
							dispatch={dispatch}
							data={data}
							containerId={containerId}
							instanceId={instanceId}
							originId={originId}
							openedPanel={openedPanel}
							setOpenedPanel={setOpenedPanel}
							insertImage={insertImage}
							resetImage={resetImage}
							removeImage={removeImage}
							images={images}
							uploads={uploads}
						/>
						<Settings
							openedPanel={openedPanel}
							setOpenedPanel={setOpenedPanel}
							currentDevice={currentDevice}
							gridspec={gridspec}
							dispatch={dispatch}
							data={data}
							instanceId={instanceId}
							originId={originId}
							save={save}
							noActive
						/>
						<Actions
							dispatch={dispatch}
							data={data}
							containerId={containerId}
							instanceId={instanceId}
							originId={originId}
							isDefaultDevice={isDefaultDevice}
						/>
					</Flex>
				</Flex>
			</Box>
		</>
	);
}

export default React.memo<TPropsControls>(Controls);
