// @flow
import React, { memo, useMemo } from 'react';
import { Box } from 'rebass';
import type { TSx } from '@graphite/types';

type TProps = $ReadOnly<{|
	sx?: TSx,
|}>;

const baseStyle = {
	backgroundColor: 'bg.primaryalt',
	height: '1px',
};

function Separator({ sx = null }: TProps) {
	const separatorStyle = useMemo(
		() => ({
			...baseStyle,
			...sx,
		}),
		[sx],
	);
	return <Box sx={separatorStyle} />;
}

Separator.defaultProps = {
	sx: null,
};

export default memo<TProps>(Separator);
