// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, Flex } from '@graphite/uneon';
import { useDispatch } from '@graphite/use-redux';
import type { THistory } from '@graphite/types';
import { historyBack, historyForward } from 'Editor/ducks/history';

type TProps = $ReadOnly<{||}>;

const flexSx = {
	alignItems: 'center',
	marginRight: '6px',
};

const boxSx = {
	marginRight: '6px',
	':last-of-type': {
		marginRight: 0,
	},
};

const boxDisabledSx = {
	...boxSx,
	opacity: 0.4,
};

function ButtonsHistory() {
	const constructorHistory: THistory = useSelector(state => state.constructorHistory);
	const { cursor, forward, history } = constructorHistory;
	const dispatch = useDispatch();

	const historyLength = history.length;

	const { isHistoryUndoDisabled, isHistoryRedoDisabled } = React.useMemo(() => {
		const isHistoryUndoDisabled = cursor < 0 || (cursor === 0 && !forward);
		const isHistoryRedoDisabled =
			cursor < 0 ||
			cursor >= historyLength ||
			(cursor === historyLength - 1 && forward);
		return {
			isHistoryUndoDisabled,
			isHistoryRedoDisabled,
		};
	}, [cursor, forward, historyLength]);

	const boundBack = React.useCallback(() => {
		dispatch(historyBack());
	}, [dispatch]);
	const boundForward = React.useCallback(() => {
		dispatch(historyForward());
	}, [dispatch]);
	// const boundDownload = React.useCallback(() => {}, []);

	return (
		<Flex sx={flexSx}>
			<Icon
				name="undo"
				iconSize={18}
				size="md"
				colors="secondary"
				sx={isHistoryUndoDisabled ? boxDisabledSx : boxSx}
				onClick={boundBack}
			/>
			<Icon
				name="redo"
				iconSize={18}
				size="md"
				colors="secondary"
				sx={isHistoryRedoDisabled ? boxDisabledSx : boxSx}
				onClick={boundForward}
			/>
			{/* <Icon
				name="clock"
				iconSize={18}
				size="md"
				colors="secondary"
				sx={boxSx}
				onClick={boundDownload}
			/> */}
		</Flex>
	);
}

export default React.memo<TProps>(ButtonsHistory);
