// @flow
import React from 'react';
import { createPortal } from 'react-dom';

type TProps = $ReadOnly<{
	children: React$Node,
}>;

const PortalForServer = () => <div />;

const Portal = (() => {
	if (typeof document === 'undefined') return PortalForServer;
	const body: HTMLElement = document.body || document.createElement('div');

	const modalRoot =
		document.getElementById('modal-root') || document.createElement('div');
	body.appendChild(modalRoot);

	return (props: TProps) => createPortal(props.children, modalRoot);
})();

export default React.memo<TProps>(Portal);
